import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const FAQ = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const faqData = [
    {
      question: "How to use the MOTA APP?",
      answer:
        "To use the MOTA App, you need to open a demat account with brokers like Zerodha, Edelweiss, Kotak Securities, Alice Blue, or Shoonya. Trading limitations range from 1 lakh to 10 lakhs, and 100% of your amount will be utilized for trading. Log in to the MOTA App between 7:30 am and 9:15 am, provide OTP for authentication, and share API details with our support team. The MOTA App will autonomously execute buying and selling orders based on your algorithm.",
    },
    {
      question: "How to check your Profit and Loss?",
      answer:
        "To check your profit and loss details, log in to your demat account using your login credentials.",
    },
    {
      question: "How to withdraw profit and deposit funds?",
      answer:
        "Your bank account details are already connected to your demat account, enabling you to withdraw profits or deposit funds effortlessly. We recommend using UPI payments for these transactions.",
    },
    {
      question: "How to get notifications from the MOTA App?",
      answer:
        "Ensure you use the same email ID and mobile number used during registration for the MOTA App. Notifications for daily trading activities will be sent accordingly.",
    },
  ];

  return (
    <div id="faq" className="w-[92vw] mx-auto py-8 sm:w-[85vw] sm:px-[4rem] mt-4 sm:mt-0 rounded-3xl bg-transparent text-white transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg">
      <div className='min-h-fit p-5'>
        <h6 className="text-[3rem] leading-[2.2rem] text-center text-tabspara mb-[1.8rem] max-xl:pb-4 transition-opacity duration-500 ease-in-out hover:opacity-75">
          Frequently Asked Questions
        </h6>
        <div className="xl:text-center text-left w-full mx-auto sm:w-full flex flex-col gap-5">
          {faqData.map((item, index) => (
            <div
              key={index}
              className="bg-white bg-opacity-90 rounded-lg shadow-md p-4 cursor-pointer transition duration-300 ease-in-out transform hover:shadow-lg hover:scale-105"
              onClick={() => toggleSection(index)}
            >
              <h3 className="text-xl font-semibold text-gray-800">{item.question}</h3>
              {openSection === index && (
                <p className="mt-2 text-gray-600">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
