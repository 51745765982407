import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import About from './components/About';
import Features from './components/Features'; // Import Features component
import FAQ from './components/FAQ';
import LandingPage from './components/LandingPage';
import User from './components/User';
import Modal from './components/Modal';
import Contact from './components/Contact';
import TradingDashboard from './components/TradingDashboard';
import Date from './components/Date';
import Testing from './components/Testing';
import TradeDetails from './components/TradeDetails';
import Price from './components/Price';
import Maintainence from './components/Maintainence';
import RegistrationPage from './components/RegistrationPage';
import DailyPlreport from './components/DailyPlreport';
import EditUserDetails from './components/EditUserDetails';

function App() {
    const contactRef = useRef(null);
    const aboutRef = useRef(null);
    const featuresRef = useRef(null);
    const faqRef = useRef(null);

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/features" element={<Features />} /> 
                <Route exact path="/faq" element={<FAQ ref={faqRef} />} />
                <Route exact path="/user_demo" element={<User />} />
                <Route exact path="/chart" element={<TradingDashboard />} />
                <Route exact path="/modal" element={<Modal />} />
                <Route exact path="/date" element={<Date />} />
                <Route exact path="/tradeDetail" element={<TradeDetails />} />
                <Route exact path="/testing" element={<Testing />} />
                <Route exact path="/pricing" element={<Price />} />
                <Route exact path="/maintainence" element={<Maintainence />} />
                <Route exact path="/register" element={<RegistrationPage />} />
                <Route exact path="/edituser" element={<EditUserDetails />} />
                <Route exact path="/dailyPL" element={<DailyPlreport />} />
                <Route exact path="/contact" element={<Contact ref={contactRef} />} />
            </Routes>
        </Router>
    );
}

export default App;
