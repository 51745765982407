import React, { useState, useEffect } from 'react';
import smallLogo from '../images/logo.png'; // Update with your actual logo image path
import { Link } from 'react-router-dom';
import io from 'socket.io-client';

const TradingDashboard = () => {
  const [marketData, setMarketData] = useState({
    bankNifty: null,
    nifty: null,
    finNifty: null,
    midcap: null,
    stocks: null,
    equity: null,
  });
  const [liveTrades, setLiveTrades] = useState([]);
  const [fromDate, setFromDate] = useState(getTodayDate());
  const [toDate, setToDate] = useState(getTodayDate());
  const [reportTypes, setReportTypes] = useState({
    pnl: true,
    trend: false,
    fixedMargin: true,
    fixedLot: false,
    cumulative: false,
  });
  const [generateClicked, setGenerateClicked] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null); // State to track selected box

  useEffect(() => {
    const socket = io('http://localhost:5000');

    socket.on('connect', () => {
      console.log('Connected to socket server');
    });

    socket.on('marketData', (data) => {
      setMarketData(data);
    });

    socket.on('liveTrade', (trade) => {
      setLiveTrades((prevTrades) => [trade, ...prevTrades]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const getBoxColorClass = (value) => {
    if (value === null) return 'bg-gray-800'; // Dark background for loading state
    return value >= 0 ? 'text-green-500 bg-gray-900' : 'text-red-500 bg-gray-900'; // Adjust colors for positive and negative values
  };

  const formatNumber = (value) => {
    if (value === null) return 'Loading...';
    return value.toFixed(2);
  };

  const handleGenerateReport = () => {
    console.log(`Generating report from ${fromDate} to ${toDate} with types:`, reportTypes);
    setGenerateClicked(true);
  };

  const handleReportTypeChange = (type) => {
    setReportTypes((prevTypes) => ({
      ...prevTypes,
      [type]: !prevTypes[type],
    }));
  };

  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    return `${year}-${month}-${day}`;
  }

  const boxes = [
    { name: 'BANK NIFTY', value: marketData.bankNifty, size: 'text-4xl' },
    { name: 'NIFTY', value: marketData.nifty, size: 'text-4xl' },
    { name: 'FIN NIFTY', value: marketData.finNifty, size: 'text-3xl' },
    { name: 'MIDCAP', value: marketData.midcap, size: 'text-3xl' },
    { name: 'STOCKS', value: marketData.stocks, size: 'text-3xl' },
    { name: 'EQUITY', value: marketData.equity, size: 'text-3xl' },
  ];

  const selectBox = (boxName) => {
    setSelectedBox(boxName);
  };

  const renderSelectedBoxContent = () => {
    if (selectedBox) {
      return (
        <div className="flex items-center justify-center my-1 mt-1 w-full text-between">
          <span className="text-gray-200 text-lg font-bold">{selectedBox}</span>
        </div>
      );
    } else {
      return null; // Return null if no box is selected
    }
  };
  
  
  

  

  return (
    <div className="h-screen flex flex-col bg-gray-800">
      {/* Navbar */}
      <nav className="bg-gray-400 p-4 flex items-center justify-between">
        {/* Left Section - Logo */}
        <div className="flex items-center">
          <img src={smallLogo} className="w-40 h-auto mr-2" alt="Logo" />
        </div>

        {/* Center Section - Title */}
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white flex-grow text-center transition duration-300 ease-in-out transform hover:scale-105">
          <span className="inline-block">USPL</span> <span className="inline-block">Paper Trade Report</span>
        </h1>

        {/* Right Section - Back Button */}
        <Link
          to="/"
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out hidden lg:block"
        >
          Back
        </Link>
      </nav>

      {/* Main Content */}
      <div className="flex flex-grow">
        {/* Left Section (40% width) */}
        <div className="flex flex-col w-2/5 bg-gray-900 p-4">
          {/* Six Boxes */}
          <h2 className="text-lg font-semibold mb-4 text-center text-white">Live Market Status</h2>
          <div className="grid grid-cols-2 gap-4">
            {boxes.map((box, index) => (
              <div
                key={index}
                className={`border border-gray-800 rounded-lg p-4 mb-4 shadow-md transition duration-200 ease-in-out transform hover:scale-105 cursor-pointer ${getBoxColorClass(
                  box.value
                )} ${selectedBox === box.name ? 'ring-2 ring-blue-500' : ''}`}
                onClick={() => selectBox(box.name)}
              >
                <h3 className={`text-lg font-semibold mb-2 ${box.size} text-white`}>{box.name}</h3>
                <div className={`text-gray-200 text-3xl font-bold ${getBoxColorClass(box.value)}`}>
                  <marquee>{formatNumber(box.value)}</marquee>
                </div>
              </div>
            ))}
          </div>

          {/* Live Trades Container (Below Six Boxes) */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4 text-center text-white">Live Trades</h2>
            <div className="overflow-y-auto h-48 border border-gray-800 rounded-lg p-4 shadow-md transition duration-200 ease-in-out transform hover:scale-105 bg-gray-700">
              {/* Live Trades List */}
              {liveTrades.length > 0 ? (
                liveTrades.map((trade, index) => (
                  <div
                    key={trade.id}
                    className={`mb-4 p-4 bg-gray-800 rounded-lg transition duration-200 ease-in-out transform hover:scale-105 ${
                      index === 0 ? 'animate-bounce' : ''
                    }`}
                  >
                    <p className="text-gray-200">Symbol: {trade.symbol}</p>
                    <p className="text-gray-200">Price: {trade.price.toFixed(2)}</p>
                    <p className="text-gray-200">Quantity: {trade.quantity}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-200 text-center">No live trades available</p>
              )}
            </div>
          </div>
        </div>

        {/* Right Section (60% width) */}
        <div className="w-3/5 bg-gray-800 p-4">
          

          {/* Container for Date Selection and Report Type */}
          <div className="flex items-center mb-4">
            <div className="flex flex-col flex-1 mr-4">
              <label className="block text-sm font-medium text-white">From Date:</label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-700 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md transition duration-200 ease-in-out hover:bg-gray-700"
              />
            </div>
            <div className="flex flex-col flex-1 mr-4">
              <label className="block text-sm font-medium text-white">To Date:</label>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-700 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md transition duration-200 ease-in-out hover:bg-gray-700"
              />
            </div>
            <button
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-5 px-10 rounded-lg transition duration-200 ease-in-out transform hover:scale-105 ${
                generateClicked ? 'bg-green-500 hover:bg-green-700' : ''
              }`}
              onClick={handleGenerateReport}
            >
              Generate
            </button>
          </div>

{/* Report Type Selection */}
<div className="flex items-center mb-4">
  <div className="flex-1">
    <label className="block text-sm font-medium text-white">Report Types:</label>
    <div className="flex flex-wrap mt-1">
      {/* P&L Report Type */}
      <div className="mr-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="pnl"
            checked={reportTypes.pnl}
            onChange={() => handleReportTypeChange('pnl')}
            className="mr-2 transition duration-200 ease-in-out transform hover:scale-105"
          />
          <label htmlFor="pnl" className="text-gray-200 cursor-pointer transition duration-200 ease-in-out hover:text-blue-600">P&L</label>
        </div>
      </div>
      {/* Trend Report Type */}
      <div className="mr-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="trend"
            checked={reportTypes.trend}
            onChange={() => handleReportTypeChange('trend')}
            className="mr-2 transition duration-200 ease-in-out transform hover:scale-105"
          />
          <label htmlFor="trend" className="text-gray-200 cursor-pointer transition duration-200 ease-in-out hover:text-blue-600">Trend</label>
        </div>
      </div>
      {/* Selected box content in the center */}
      <div className="col-span-1 flex justify-center items-center text-center flex-grow">
        <div className="text-20xl font-bold transition duration-200 ease-in-out transform hover:scale-105">
          {renderSelectedBoxContent()}
        </div>
      </div>
      {/* Fixed Margin Report Type */}
      <div className="mr-4 ml-auto">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="fixedMargin"
            checked={reportTypes.fixedMargin}
            onChange={() => handleReportTypeChange('fixedMargin')}
            className="mr-2 transition duration-200 ease-in-out transform hover:scale-105"
          />
          <label htmlFor="fixedMargin" className="text-gray-200 cursor-pointer transition duration-200 ease-in-out hover:text-blue-600">Fixed Margin</label>
        </div>
      </div>
      {/* Fixed Lot Report Type */}
      <div className="mr-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="fixedLot"
            checked={reportTypes.fixedLot}
            onChange={() => handleReportTypeChange('fixedLot')}
            className="mr-2 transition duration-200 ease-in-out transform hover:scale-105"
          />
          <label htmlFor="fixedLot" className="text-gray-200 cursor-pointer transition duration-200 ease-in-out hover:text-blue-600">Fixed Lot</label>
        </div>
      </div>
      {/* Cumulative Report Type */}
      <div className="mr-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="cumulative"
            checked={reportTypes.cumulative}
            onChange={() => handleReportTypeChange('cumulative')}
            className="mr-2 transition duration-200 ease-in-out transform hover:scale-105"
          />
          <label htmlFor="cumulative" className="text-gray-200 cursor-pointer transition duration-200 ease-in-out hover:text-blue-600">Cumulative</label>
        </div>
      </div>
    </div>
  </div>
</div>



          {/* Trades Display Container */}
          <div className="mt-8 rounded-lg bg-gray-900 shadow-md p-8 md:p-12 lg:p-16 transition duration-200 ease-in-out transform hover:scale-102">
            <h2 className="text-lg font-semibold mb-4 text-center text-white">Display the trades</h2>
            <div className="border border-gray-800 rounded-lg p-12 md:p-16 lg:p-20 shadow-md transition duration-200 ease-in-out transform hover:scale-102 bg-gray-700">
              <p className="text-gray-200 text-center">Display the Trades.</p>
            </div>
          </div>

          {/* Total P&L and Points Gain */}
          <div className="mt-8 rounded-lg bg-gray-900 shadow-md p-4 transition duration-200 ease-in-out transform hover:scale-102">
            <h2 className="text-lg font-semibold mb-4 text-center text-white"></h2>
            <div className="flex justify-between mb-4">
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-200">Total P&L:</span>
                <span className="text-lg font-semibold text-gray-100">0.00</span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-200">Points Gain:</span>
                <span className="text-lg font-semibold text-gray-100">0.00</span>
              </div>
            </div>
          </div>

          {/* Statistics */}
          <div className="mt-8 rounded-lg bg-gray-900 shadow-md p-4 transition duration-200 ease-in-out transform hover:scale-102">
            <h2 className="text-lg font-semibold mb-4 text-center text-white">Statistics</h2>
            <div className="flex justify-between mb-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingDashboard;
