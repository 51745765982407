import React, { useEffect } from 'react';

import 'aos/dist/aos.css'; 
import AOS from 'aos'; 

import Tooltip from '@mui/material/Tooltip'; 

const Demo = () => {
    
    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true,
        });
    }, []);

    return (
        <div className="w-[92vw] mx-auto py-[4rem] sm:w-[85vw] sm:px-[3rem] my-20 rounded-3xl bg-[#E1DDF6]">
            <div className="min-h-fit">
                <div
                    className="xl:text-center text-left w-60% mx-auto bg-pink sm:w-full flex flex-col items-center gap-8 p-5 aos-init aos-animate"
                    data-aos="fade-up" 
                >
                    <h3
                        className="text-4xl font-medium text-center transition duration-500 ease-in-out transform hover:scale-105 text-[#51087E] mb-8"
                        data-aos="fade"
                    >
                        We offer cloud server services for hosting your{' '}
                        <span className="text-[#7E22CE] transition duration-500 ease-in-out transform hover:scale-105">
                            algorithmic logic
                        </span>
                        <br />
                        and automating trading directly from your demat account.
                    </h3>
                    <Tooltip
                        title="For hosting your algorithm and executing auto trades into your demat account: USPL will charge a service fee of 15% on profits & Losses. These charges will be applied on a monthly basis."
                        arrow
                        classes={{ tooltip: 'custom-tooltip' }} 
                        PopperProps={{
                            style: { fontSize: '3.5rem', backgroundColor: '#51087E' } 
                        }}
                    >
                        <button
                            className="bg-[#51087E] flex items-center rounded-full text-3xl text-white px-6 py-3 font-medium hover:bg-purple-700 transition duration-300 ease-in-out transform hover:scale-105 shadow-md hover:shadow-lg hover:text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                            data-aos="fade"
                        >
                            <span className="transition duration-500 ease-in-out transform hover:scale-105">
                                USPL CHARGES
                            </span>
                        </button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default Demo;
