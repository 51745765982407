import React, { useState } from 'react';
import { Grid, Toolbar, AppBar, Tabs, Tab, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom'; 
import { animateScroll as scroll, scroller } from 'react-scroll';
import logo from "../images/logo.png";

const Navbar = ({ contactRef, aboutRef, featuresRef, faqRef }) => {
    const [value, setValue] = useState(0); 
    const location = useLocation(); 
    const [drawerOpen, setDrawerOpen] = useState(false); 

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleDrawer = (open) => (event) => {
        setDrawerOpen(open);
    };

    const handleScrollTo = (section) => {
        scroller.scrollTo(section, {
            smooth: true,
            duration: 800,
        });
        setDrawerOpen(false); 
    };

    const handleTabClick = (event, newValue) => {
        
        event.preventDefault();
        handleScrollTo(newValue);
    };

    const drawerContent = (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button onClick={() => handleScrollTo("home")}>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={() => handleScrollTo("about")}>
                    <ListItemText primary="About Us" />
                </ListItem>
                <ListItem button onClick={() => handleScrollTo("features")}>
                    <ListItemText primary="Features" />
                </ListItem>
                <ListItem button onClick={() => handleScrollTo("faq")}>
                    <ListItemText primary="FAQ'S" />
                </ListItem>
                <ListItem button onClick={() => handleScrollTo("contact")}>
                    <ListItemText primary="Contact" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <>
            <AppBar position='sticky' sx={{ backgroundColor: '#ffffff', color: '#000000' }}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item xs={6} md={2}>
                            <RouterLink to="/">
                                <img width={150} src={logo} alt="Logo" />
                            </RouterLink>
                        </Grid>
                        <Grid item xs={6} md={7}>
                            <Tabs
                                value={value}
                                onChange={handleTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab
                                    label="Home"
                                    onClick={(event) => handleTabClick(event, "home")}
                                    component="a"
                                    href=""
                                />
                                <Tab
                                    label="About Us"
                                    onClick={(event) => handleTabClick(event, "about")}
                                    component="a"
                                    href="src\components\About.js"
                                />
                                <Tab
                                    label="Features"
                                    onClick={(event) => handleTabClick(event, "features")}
                                    component="a"
                                    href="src\components\Features.js"
                                />
                                <Tab
                                    label="FAQ'S"
                                    onClick={(event) => handleTabClick(event, "faq")}
                                    component="a"
                                    href="src\components\FAQ.js"
                                />
                                <Tab
                                    label="Contact"
                                    onClick={(event) => handleTabClick(event, "contact")}
                                    component="a"
                                    href="src\components\Contact.js"
                                />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawerContent}
            </Drawer>
        </>
    );
};

export default Navbar;
