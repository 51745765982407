import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa';

const ContactUs = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted!');
    
  };

  return (
    <div id="contact" className="w-[92vw] mx-auto py-8 sm:w-[85vw] sm:px-[4rem] mt-4 sm:mt-0 rounded-3xl transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg">
      <div className='min-h-fit p-5 bg-white rounded-3xl'>
        <h6 className="text-[3rem] leading-[2.2rem] text-center text-tabspara mb-[1.8rem] max-xl:pb-4 text-slate-600 transition-opacity duration-500 ease-in-out hover:opacity-75">
          Contact Us
        </h6>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          
          <div className="flex flex-col gap-4">
            <h3 className='text-4xl font-bold decoration-solid transition-transform duration-500 ease-in-out transform hover:scale-110'>
              Get In Touch With Us
            </h3>
            <p className='text-lg text-slate-600 transition-opacity duration-500 ease-in-out hover:opacity-90'>
              Have questions or need assistance? Reach out to us via phone or email. Our dedicated team is here to help you with any inquiries regarding our services, trading platforms, or general support.
            </p>
            <div className="flex items-center gap-4 mt-4 text-gray-700">
              <FaPhone className="text-xl" />
              <p>Call us at <a href="tel:+123456789">+1 234 567 89</a></p>
            </div>
            <div className="flex items-center gap-4 mt-2 text-gray-700">
              <FaEnvelope className="text-xl" />
              <p>Email us at <a href="mailto:info@example.com">info@example.com</a></p>
            </div>
          </div>

          
          <div className="flex flex-col gap-4">
            <h3 className='text-4xl font-bold decoration-solid transition-transform duration-500 ease-in-out transform hover:scale-110'>
              Contact Form
            </h3>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
              <input type="text" placeholder="Your Name" className="border-2 border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300 ease-in-out" />
              <input type="email" placeholder="Your Email" className="border-2 border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300 ease-in-out" />
              <textarea rows="4" placeholder="Your Message" className="border-2 border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300 ease-in-out"></textarea>
              <button type="submit" className="bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-3 rounded-lg transition duration-300 ease-in-out">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
