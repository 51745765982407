import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Hero.css';
import demoVideo from '../images/demo-video.mp4';


const Hero = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="hero-container">
      
      <video className="video-background" autoPlay muted loop>
        <source src={demoVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>


      
      <div className="hero-content">
        <div className="content-inner">
          <div className="text-container">
            <h1 className="hero-title">Empower Your Trading Journey</h1>
            <p className="hero-description">
              USPL focuses on creating a customized Algo Trading App called MOTA, specifically designed for your options trading needs, especially in the F&O segment. We customize the logic based on your trading preferences to develop a unique intraday options buying strategy using your account.
            </p>
            <div className="button-container">
              <Link to="/user_demo">
                <button className="hero-button get-started-button">Get Started</button>
              </Link>
              <button className="hero-button watch-demo-button" onClick={() => setModalOpen(true)}>
                Watch Demo
              </button>
            </div>
          </div>
        </div>
      </div>

      
      {modalOpen && (
        <div className="video-modal">
          <div className="video-modal-content">
            <button className="close-button" onClick={() => setModalOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <video className="modal-video" autoPlay controls>
              <source src={demoVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
