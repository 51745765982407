import React from 'react';
import { Link } from 'react-router-dom';

const Features = () => {
  const features = [
    {
      title: 'Secure Trading Environment',
      description:
        "Trade confidently with Ultimate Scaler's robust security. Our encrypted transactions ensure that your strategies remain confidential and secure.",
    },
    {
      title: 'Real Time Data Collection',
      description:
        'Our advanced backend processes real-time data, guaranteeing up-to-date information. Using sophisticated algorithms, we identify patterns and trends to deliver precise, actionable insights.',
    },
    {
      title: 'Automated Trading Simplicity',
      description:
        'Automate CALL or PUT options decisions with our Option Trading software, available on both Mobile & Desktop. Our advanced machine learning algorithms analyze historical and real-time market data, predicting movements with high accuracy. Simplify trading with USPL MOTA, eliminating technical complexities.',
    },
  ];

  return (
    <div id="features" className="w-[92vw] mx-auto py-8 sm:w-[85vw] sm:px-[4rem] mt-4 sm:mt-0 rounded-xl overflow-hidden transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg">
      <div className="p-5 bg-transparent rounded-xl">
        <h2 className="text-3xl leading-8 text-center text-slate-600 mb-8 max-xl:pb-4 transition-opacity duration-500 ease-in-out hover:opacity-75">
          <span className="bg-white px-3 py-1 rounded-lg shadow-lg hover:shadow-xl">Features Overview</span>
        </h2>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            {features.map((feature, index) => (
              <div key={index} className="p-6 bg-white bg-opacity-90 rounded-xl shadow-lg border-4 border-indigo-500 hover:border-indigo-600 hover:shadow-2xl transition duration-500 ease-in-out transform hover:scale-105 hover:bg-gray-50">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
          <p className="mt-6 text-center text-gray-700"><Link to="/features" className="text-indigo-600 hover:underline"></Link></p>
        </div>
      </div>
    </div>
  );
}

export default Features;
