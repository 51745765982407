import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div id="about" className="w-[92vw] mx-auto py-8 sm:w-[85vw] sm:px-[4rem] mt-4 sm:mt-0 rounded-3xl bg-[#E1DDF6] transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg">
      <div className='min-h-fit p-5'>
        <h6 className="text-[3rem] leading-[2.2rem] text-center text-tabspara mb-[1.8rem] max-xl:pb-4 text-slate-600 transition-opacity duration-500 ease-in-out hover:opacity-75">
          USPL Overview
        </h6>
        <div className="xl:text-center text-left w-60% mx-auto sm:w-full flex flex-col gap-5 transition-all duration-500 ease-in-out transform hover:scale-105 hover:bg-gray-100 p-5 rounded-lg">
          <h3 className='text-4xl font-bold decoration-solid transition-transform duration-500 ease-in-out transform hover:scale-110'>
            We Buy Greed & Fear and Sell Gain & Profits
          </h3>
          <p className='text-lg text-slate-600 transition-opacity duration-500 ease-in-out hover:opacity-90'>
            At Ultimate Scaler Pvt. Ltd., our approach is to buy your  greed & fear and we sell gains & profits, leveraging market psychology for optimal timing and profitability.

            Our proprietary trading application, MOTA, uses advanced technology to analyze real-time data and execute orders automatically. This ensures swift, accurate trades, giving traders an edge in the fast-paced financial market. MOTA's smart algorithms and user-friendly interface make trading seamless and stress-free by identifying optimal entry and exit points and allowing users to monitor automated decisions effortlessly.

            We focus only on options trading for Bank Nifty, Nifty 50, Nifty Finance, Mid Cap, Stock Options, and Nifty Next 50, while also encouraging users to trade in the equities market for a balanced portfolio.

            Our goal at Ultimate Scaler Pvt. Ltd. is to empower traders with efficient, effective, and profitable tools, whether they are seasoned investors or newcomers.
          </p>
          <p className="mt-4 text-gray-700"> <Link to=""></Link>.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
